import { extend } from "vee-validate";
import { confirmed, email, max, min, required } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});

extend("required_existing_project", {
  ...required,
  message: "Please select an existing active project",
});

extend("email", {
  ...email,
  message: "Please enter a valid email",
});

extend("confirmed", {
  ...confirmed,
  message: "The Passwords entered do not match",
});

extend("pass_min", {
  ...min,
  message: "Your Password must be at least 8 characters",
});

extend("user_min", {
  ...min,
  message: "Your Username must be at least 5 characters",
});

extend("name_min", {
  ...min,
  message: "Names must be at least 5 characters",
});

extend("name_max", {
  ...max,
  message: "Names must be less than 20 characters",
});
