import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";

Vue.use(Vuex);

export default new Vuex.Store({
  state: { userTheme: "light-theme" },
  mutations: {
    setTheme: (state, theme) => {
      state.userTheme = theme;
    },
  },
  getters: {
    userTheme: (state) => state.userTheme,
  },
  actions: {},
  modules: {
    auth,
  },
});
