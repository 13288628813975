<template>
  <b-navbar
    class="snap-point"
    v-bind:class="{
      'is-very-dark': isDarkMode,
    }"
  >
    <template slot="brand">
      <b-navbar-item @click="logoRedirect()">
        <img
          src="../assets/images/logo.svg"
          alt="Santiqi Logo"
          v-bind:class="{ 'invert-icon-color': isDarkMode }"
        />
      </b-navbar-item>
    </template>

    <template slot="start">
      <!-- LHS buttons -->
      <b-navbar-item tag="router-link" :to="{ name: 'Home' }"
        >Home</b-navbar-item
      >
      <b-navbar-item tag="router-link" :to="{ name: 'About' }"
        >About</b-navbar-item
      >

      <!-- LHS authenticated buttons-->
      <template v-if="this.isAuthenticated">
        <b-navbar-item tag="router-link" :to="{ name: 'Dashboard' }"
          >Dashboard</b-navbar-item
        >
        <b-navbar-item tag="router-link" :to="{ name: 'Join_project' }"
          >Join Project</b-navbar-item
        >
        <b-navbar-item tag="router-link" :to="{ name: 'Project_view' }"
          >Project View</b-navbar-item
        >
        <b-navbar-item tag="router-link" :to="{ name: 'New_project' }"
          >New Project</b-navbar-item
        >
        <b-navbar-item tag="router-link" :to="{ name: 'New_task' }"
          >New Task</b-navbar-item
        >
      </template>
    </template>

    <template slot="end">
      <template v-if="this.isAuthenticated">
        <!-- Authenticated RHS buttons -->
        <b-navbar-item tag="router-link" :to="{ name: 'Account' }"
          ><b-tooltip
            label="Account"
            position="is-bottom"
            type="is-primary"
            :delay="1000"
          >
            <i class="fas fa-user-alt"></i></b-tooltip
        ></b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ name: 'Settings' }"
          ><b-tooltip
            label="Settings"
            position="is-bottom"
            type="is-primary"
            :delay="1000"
            ><i class="fas fa-cog"></i
          ></b-tooltip>
        </b-navbar-item>

        <b-navbar-item tag="div">
          <button class="button is-light" @click="confirmSignOut">
            Log out
          </button>
        </b-navbar-item>
      </template>

      <template v-else>
        <b-navbar-item tag="div">
          <div class="buttons">
            <!-- Unathenticated RHS buttons -->
            <button
              class="button is-primary"
              @click="$router.push({ name: 'Register' })"
            >
              <strong>Join</strong>
            </button>
            <button
              class="button is-light"
              @click="$router.push({ name: 'Login' })"
            >
              Sign in
            </button>
          </div>
        </b-navbar-item>
      </template>
    </template>
  </b-navbar>
</template>

<script>
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";

export default {
  name: "TheNavbar",

  data() {
    return {
      isAuthenticated: false,
      auth: {},
    };
  },

  props: {
    isDarkMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    logoRedirect() {
      if (this.isAuthenticated) {
        this.$router.push({ name: "Dashboard" });
      } else {
        this.$router.push({ name: "Home" });
      }
    },

    confirmSignOut() {
      this.$buefy.dialog.confirm({
        message: "Log out?",
        onConfirm: () => {
          this.signUserOut();
        },
      });
    },

    signUserOut() {
      const auth = getAuth();
      signOut(auth)
        .then(() => {
          // Sign-out successful.
          this.$router.replace({ name: "Login" });
          this.$buefy.toast.open("Log Out Successful");
        })
        .catch((error) => {
          // An error happened.
          console.log(error);
          console.log("errrrrrrrrrrrrror");
          this.$buefy.toast.open({
            duration: 5000,
            message: `Log Out Unsuccessful`,
            position: "is-bottom",
            type: "is-danger",
          });
        });
    },
  },

  created() {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      // Check for user status
      this.isAuthenticated = !!user;
    });
  },
};
</script>
