import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  { path: "/home", redirect: { name: "Home" } },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
    meta: { title: "Login" },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register.vue"),
    meta: { title: "Register" },
  },

  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/Dashboard.vue"),
    meta: { title: "Dashboard", requiresAuth: true },
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("../views/Account.vue"),
    meta: { title: "Account", requiresAuth: true },
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../views/Settings.vue"),
    meta: { title: "Settings", requiresAuth: true },
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
    meta: { title: "About" },
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("../views/Terms.vue"),
    meta: { title: "Terms" },
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("../views/Privacy.vue"),
    meta: { title: "Privacy" },
  },
  {
    path: "/reset_request",
    name: "Reset_request",
    component: () => import("../views/Reset_request.vue"),

    meta: { title: "Reset Password" },
  },
  {
    path: "/reset_token",
    name: "Reset_token",
    component: () => import("../views/Reset_token.vue"),

    meta: { title: "Reset Token" },
  },
  {
    path: "/project_view",
    name: "Project_view",
    component: () => import("../views/Project_view.vue"),
    meta: { title: "Project View", requiresAuth: true },
  },
  {
    path: "/project/:pid",
    name: "Project",
    component: () => import("../views/Project.vue"),
    meta: { title: "Project", requiresAuth: true },
  },
  // No project redirect.
  { path: "/project", redirect: "Project_view" },
  {
    path: "/join_project",
    name: "Join_project",
    component: () => import("../views/Join_project.vue"),
    meta: { title: "Join Project", requiresAuth: true },
  },
  {
    path: "/task/:tid",
    name: "Task",
    component: () => import("../views/Task.vue"),
    meta: { title: "Task", requiresAuth: true },
  },
  // No task redirect.
  { path: "/task", redirect: "Project_view" },
  {
    path: "/new_task/:project?",
    name: "New_task",
    component: () => import("../views/New_task.vue"),
    meta: { title: "Project Task", requiresAuth: true },
  },
  {
    path: "/new_project",
    name: "New_project",
    component: () => import("../views/New_project.vue"),
    meta: { title: "New Project", requiresAuth: true },
  },
  // 404 page.
  {
    path: "*",
    name: "Not_found",
    component: () => import("../views/Not_found.vue"),
    meta: { title: "Not Found" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkExactActiveClass: "is-active",
});

// Function for avoiding redundant reroutes to same page.
function patchRouterMethod(router, methodName) {
  router["old" + methodName] = router[methodName];
  router[methodName] = async function (location) {
    return router["old" + methodName](location).catch((error) => {
      if (error.name === "NavigationDuplicated") {
        return this.currentRoute;
      }
      throw error;
    });
  };
}

patchRouterMethod(router, "push");
patchRouterMethod(router, "replace");

// Login rerouting function.
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // This route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.auth.user) {
      next({
        replace: true,
        name: "Login",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next(); // Make sure to always call next()!
  }

  // Smoothly scroll to the top of the page on page change.
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
});

// Update page title.
router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + " | Santiqi";
  } else {
    document.title = "Santiqi";
  }
});

export default router;
