import "@mdi/font/css/materialdesignicons.css";
import axios from "axios";
import Buefy from "buefy";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./utils/vee-validate";

Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.use(Buefy);

// Your web app's Firebase configuration.
const firebaseConfig = {
  apiKey: "AIzaSyB3wpGztR6mbABAEY0FqBGGM0mhWMTvEWE",
  authDomain: "solute-test.firebaseapp.com",
  databaseURL: "https://solute-test.firebaseio.com",
  projectId: "solute-test",
  storageBucket: "solute-test.appspot.com",
  messagingSenderId: "856303411912",
  appId: "1:856303411912:web:f34fc002f1c541d38a7bfd",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

let vueApp;
const auth = getAuth(firebaseApp);
onAuthStateChanged(auth, (user) => {
  // Check for user status

  store.commit("updateUser", { user });
  console.log(user);
  if (!vueApp) {
    vueApp = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
