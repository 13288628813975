import Vue from "vue";

export default {
  state: {
    token: null,
    user: null,
  },
  mutations: {
    updateUser(state, { user }) {
      Vue.set(state, "user", user);
    },
  },
  getters: {
    user: (state) => state.user,
  },
  actions: {},
};
