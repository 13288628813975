<template>
  <div
    id="app"
    class="has-stripe"
    v-bind:class="{
      'has-stripe-dark': isDarkMode,
    }"
  >
    <!-- Accessibility skip button. -->
    <button
      onclick="window.location.href = '#main'"
      type="button"
      class="button skip-to-main-button"
    >
      Skip to main content
    </button>
    <TheNavbar ref="home" :isDarkMode="isDarkMode" />
    <main id="main" role="main">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </main>
    <TheFooter :isDarkMode="isDarkMode" />
  </div>
</template>

<script>
import TheFooter from "@/components/TheFooter.vue";
import TheNavbar from "@/components/TheNavbar.vue";
import { mapGetters } from "vuex";
export default {
  name: "App",
  components: { TheNavbar, TheFooter },
  computed: {
    ...mapGetters(["userTheme"]),
    isDarkMode: {
      get() {
        if (this.userTheme == "dark-theme") {
          return true;
        }
        return false;
      },
      set() {},
    },
  },
  methods: {
    focusOnMain() {
      const editButtonRef = this.$refs.main;
      editButtonRef.focus();
    },
    setTheme(theme) {
      this.$store.commit("setTheme", theme);
      localStorage.setItem("user-theme", theme);
    },
    getBrowserThemePreference() {
      const hasDarkPreference = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      if (hasDarkPreference) {
        return "dark-theme";
      } else {
        return "light-theme";
      }
    },
    getLocalStorageTheme() {
      return localStorage.getItem("user-theme");
    },
    findHighestPriorityTheme() {
      //Check user's saved theme if logged in.
      //Check locally stored saved theme and Check browser theme preference.
      return this.getLocalStorageTheme() ?? this.getBrowserThemePreference();
    },
    initializeTheme() {
      this.setTheme(this.findHighestPriorityTheme(this));
    },
  },
  mounted() {
    this.initializeTheme();
  },
  watch: {
    $route() {
      document.body.tabIndex = 0;
      document.body.focus();
    },
  },
};
</script>

<style scoped>
.skip-to-main-button:focus {
  transform: translateY(0);
}
.skip-to-main-button {
  margin: 1rem;
  z-index: 999;
  position: absolute;
  transform: translateY(-200%);
  transition: transform 0.4s;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.4s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<style>
/* Global Styles */
@import "./assets/styles/style.css";
</style>
