<template>
  <footer class="footer has-background-very-dark has-text-white snap-point">
    <div class="content has-text-centered pt-4">
      <div class="mb-2">
        <a
          class="ml-2 has-text-white"
          tabindex="0"
          @keyup.enter="$router.push({ name: 'Terms' })"
          @click="$router.push({ name: 'Terms' })"
          >Terms
        </a>
        <span>&nbsp;|</span>
        <a
          class="ml-2 has-text-white"
          tabindex="0"
          @keyup.enter="$router.push({ name: 'Privacy' })"
          @click="$router.push({ name: 'Privacy' })"
          >Privacy</a
        >
      </div>
      <small>© 2025 Akaash BP, All rights reserved.</small>
    </div>
  </footer>
</template>

<script>
export default {
  name: "TheFooter",
  props: {
    isDarkMode: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style scoped>
strong {
  color: white;
}
</style>
